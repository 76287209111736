import { Dispatch } from 'redux'
import * as actions from '../actions'
import * as linkCreateActions from '../../link-create/actions'
import { UserActions } from '../types'
import { LinkCreateActions } from '../../link-create/types'
import { LinkActions } from '../../link/types'
import { LinkdropPay } from 'linkdrop-p2p-sdk'
import { defineJSONRpcUrl } from 'helpers'
import { ethers } from 'ethers'
import switchNetwork from './switch-network'
import { providers } from 'ethers'

const { REACT_APP_INFURA_ID, REACT_APP_ZUPLO_API_KEY } = process.env


const initialize = (
  connector: any,
  urlChainId?: string,
  urlTokenAddress?: string,
  userAddress?: string | null
) => {
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<LinkActions> & Dispatch<LinkCreateActions>
  ) => {
    if (userAddress && connector) {
      const provider = new providers.Web3Provider(await connector.getProvider())
      const network = await provider.getNetwork()
      dispatch(actions.setHasConnector(true))
      dispatch(actions.setAddress(userAddress))
      if (urlTokenAddress) {
        dispatch(linkCreateActions.setTokenAddress(urlTokenAddress))
      }
 
      const signer = await provider.getSigner()

      if (urlChainId && (network.chainId !== Number(urlChainId))) {
        await switchNetwork(provider, Number(urlChainId))
      } else {
        const jsonRpcUrl = defineJSONRpcUrl({
          chainId: Number(network.chainId),
          infuraPk: REACT_APP_INFURA_ID as string
        })

        dispatch(actions.setChainId(network.chainId))
        const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)
        dispatch(actions.setProvider(jsonRpcProvider))
        dispatch(actions.setUserProvider(provider))
        dispatch(actions.setSigner(signer))
        const sdk = new LinkdropPay({
          apiKey: REACT_APP_ZUPLO_API_KEY as string,
          baseUrl: window.location.origin
        })

        dispatch(actions.setSDK(sdk)) 
        dispatch(actions.setInitialized(true))
      }
    }
  }
}

export default initialize