import { FC } from 'react'
import TProps from './types'
import AmountPreview from '../amount-preview'

const ERC20TokenPreview: FC<TProps> = ({
  image,
  amount,
  tokenAddress,
  name,
  chainId
}) => {
  return <AmountPreview
    finished
    amount={amount}
    image={image}
    chainId={chainId}
    name={name}
    tokenAddress={tokenAddress}
  />
}

export default ERC20TokenPreview