
import { Dispatch } from 'redux'
import * as actionsLink from '../actions'
import { LinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState, IAppDispatch } from 'data/store'
import { LinkCreateActions } from '../../link-create/types'
import { alertError, defineNetworkName } from 'helpers'

export default function getTransferId(
  txHash: string,
  chainId: string,
  callback?: (location: string) => void
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<LinkCreateActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    dispatch(actionsLink.setLoading(true))

    const {
      links: {
        links
      },
      user: {
        provider,
        sdk
      }
    } = getState()

    try {
      const claimLink = await sdk?.retrieveClaimLink({
        txHash, chainId: Number(chainId)
      })

      if (claimLink) {
        const networkName = defineNetworkName(Number(chainId))
        const symbol = 'usdc'
        callback && callback(`/links/${networkName}/${symbol}/${claimLink.sender}/${claimLink.transferId}`)
      } else {
        throw new Error('No transfer ID detected')
      }
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
      alertError('Some error occured. Please check console for more info')
    }
    dispatch(actionsLink.setLoading(false))
  }
}
