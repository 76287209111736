import { Dispatch } from 'redux'
import {
  LinkCreateActions,
} from '../types'
import {
  UserActions,
} from '../../user/types'
import {
  alertError,
  checkDepositTxHash,
  defineNetworkName,
  defineAmountRange
} from 'helpers'
import { RootState } from 'data/store'
import * as actionCreateLink from '../actions'
import * as actionUser from '../../user/actions'
import { TCustomSigner } from 'types'
import { plausibleApi } from 'data/api'

const secure = (
  callback: () => void
) => {
  return async (
    dispatch: Dispatch<LinkCreateActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))
    const { user: { sdk, provider, chainId, address, signer }, linkCreate: { tokenAddress, tokenAmount, humanReadableAmount } } = getState()

    try {

      if (!sdk) {
        return alertError('SDK is not found')
      }
  
      if (!tokenAddress) {
        return alertError('tokenAddress is not defined')
      }

      const expiration = String(Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 30)
      const claimLink = await sdk.createClaimLink({
        token: tokenAddress,
        amount: String(tokenAmount),
        expiration,
        chainId: Number(chainId),
        from: address
      })
      // 0.0001

      if (claimLink) {
        const txHash = await claimLink.depositWithAuthorization({
          signTypedData: async (domain, types, message) => await (signer as TCustomSigner)._signTypedData(domain, types, message)
        }) // TX hash
        const transferId = claimLink.transferId as string

        if (txHash) {
          plausibleApi.invokeEvent({
            eventName: 'signed_to_deposit',
            data: {
              token_amount: defineAmountRange(humanReadableAmount),
              network: defineNetworkName(chainId)
            }
          })
          await checkDepositTxHash(txHash, chainId as number, sdk, transferId, address)
          dispatch(actionUser.setClaimLink(claimLink)) 
          dispatch(actionCreateLink.setLoading(false))
          plausibleApi.invokeEvent({
            eventName: 'crypto_deposited',
            data: {
              network: defineNetworkName(chainId),
            }
          })
          dispatch(actionCreateLink.setStep('transaction_sign'))
          if (callback) {
            callback()
          } else {
            dispatch(actionCreateLink.setLoading(false))
          }
        }
      }
      
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        alertError('You cancelled the link creation. Please try again')
      } else {
        alertError('Some error occured, please check console for more information')
      }
      console.error({ err })

      dispatch(actionCreateLink.setLoading(false))
    }
  }
}

export default secure

