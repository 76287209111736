
import { Dispatch } from 'redux'
import * as actionCreateLink from '../actions'
import * as asyncActionsToken from '../../token/async-actions'
import { LinkCreateActions } from '../types'
import { TokenActions } from '../../token/types'
import getTokenBalance from '../../token/async-actions/get-token-balance'
import { ethers, BigNumber } from 'ethers'
import { ERC20Contract } from 'abi'
import { RootState } from 'data/store'

export default function getInitialData(
  tokenAmount: string
) {
  return async (
    dispatch: Dispatch<LinkCreateActions> & Dispatch<TokenActions>,
    getState: () => RootState
  ) => {
    
    try {
      dispatch(actionCreateLink.setLoading(true))
      
      const {
        user: {
          provider,
          address,
          chainId
        },
        linkCreate: {
          tokenAddress,
          tokenType,
          step
        }
      } = getState()

      if (
        tokenType && tokenAddress && chainId && address
      ) {
        await asyncActionsToken.getTokenData(
          tokenType,
          tokenAddress,
          dispatch
        )
        
        const contractInstance = new ethers.Contract(tokenAddress, ERC20Contract, provider)
        await getTokenBalance(
          dispatch,
          address,
          contractInstance
        )
      }

      dispatch(actionCreateLink.setLoading(false))
      dispatch(actionCreateLink.setInitialized(true))
      if (step !== 'transaction_sign') {
        dispatch(actionCreateLink.setStep('initial'))
        if (tokenAmount && !isNaN(Number(tokenAmount))) {
          dispatch(actionCreateLink.setAmount(BigNumber.from(tokenAmount)))
        }
      }
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
    }
  }
}
