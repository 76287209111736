
import { Dispatch } from 'redux';
import { LinkActions } from '../types'
import { UserActions } from '../../user/types'
import { TokenActions } from '../../token/types'
import * as actionsLink from '../actions'
import * as actionsToken from '../../token/actions'
import { RootState } from 'data/store'
import {
  alertError,
  defineNetworkName,
  defineTokenAddressBySymbol
} from 'helpers'
import * as actionUser from '../../user/actions'
import { plausibleApi } from 'data/api'

export default function getLinkByCode(
  link: string,
  tokenSymbol: string
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<UserActions> & Dispatch<TokenActions>,
    getState: () => RootState
  ) => {
    const {
      user: {
        sdk,
        chainId
      }
    } = getState()
    const networkName = defineNetworkName(chainId)

    dispatch(actionsLink.setFullLink(link))
    dispatch(actionsLink.setLoading(true))
    dispatch(actionsLink.setError(null))

    if (!sdk) {
      return alert('No sdk found')
    }
    try {
      const claimLink = await sdk.getClaimLink(link)
      if (claimLink) {
        const { amount, expiration } = claimLink
        dispatch(actionUser.setClaimLink(claimLink)) 

        plausibleApi.invokeEvent({
          eventName: 'link_data_fetch_success',
          data: {
            network: networkName
          }
        })
        const statusData = await claimLink.getStatus()
        if (statusData && statusData.status  === 'redeemed') {
          dispatch(actionsLink.setIsClaimed(true))
        }
        dispatch(actionsLink.setExpirationTime(String(expiration)))
        dispatch(actionsToken.setAmount(String(amount)))
        dispatch(actionsLink.setType('ERC20'))
        const tokenAddress = defineTokenAddressBySymbol(tokenSymbol, chainId)
        dispatch(actionsToken.setTokenAddress(tokenAddress))
        dispatch(actionsLink.setInitialized(true))
      }
      
    } catch (err) {
      alertError('Some error occured, please check console')
      plausibleApi.invokeEvent({
        eventName: 'link_data_fetch_errored',
        data: {
          error: 'request_failed',
          network: networkName
        }
      })
      console.log({ err })
    }
    dispatch(actionsLink.setLoading(false))
  } 
}