
import { Dispatch } from 'redux'
import * as actionsToken from '../actions'
import { TokenActions } from '../types'
import { LinkCreateActions } from '../../link-create/types'
import { TTokenType } from 'types'
import USDC from 'images/usdc.png'


const getTokenData = async (
  tokenType: TTokenType,
  tokenAddress: string,
  dispatch: Dispatch<LinkCreateActions> & Dispatch<TokenActions>
) => {
  try {
    if (tokenType === 'ERC20' && tokenAddress) {
      // const { symbol, decimals, image } = await getERC20Data(provider, tokenAddress, chainId)
      dispatch(actionsToken.setName('USDC'))
      dispatch(actionsToken.setImage(USDC))
      dispatch(actionsToken.setDecimals(6))
    }
  } catch (
    error: any
  ) {
    console.log(error, error.statusCode)
  }
}

export default getTokenData
