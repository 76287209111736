import { ethers } from 'ethers'
import { LinkdropPay, ClaimLink } from 'linkdrop-p2p-sdk'

type TCheckDepositTxHash = (
  txHash: string,
  chainId: number,
  sdk: LinkdropPay,
  transferId: string,
  address: string
) => Promise<boolean | {amount: number}>

const checkDepositTxHash: TCheckDepositTxHash = (txHash, chainId, sdk, transferId, address) => {
  return new Promise((resolve, reject) => {
    (async () => {
      const link = await sdk.retrieveClaimLink({
        txHash,
        chainId,
        transferId,
        sender: address
      })
      const interval = window.setInterval(async () => {
        if (link) {
          const statusData = await link.getStatus()
          if (statusData) {
            const { status } = statusData
            if (status === 'deposited') {
              resolve(true)
              clearInterval(interval)
            }
          }
        }
      }, 1000)
    
    })()
  })
}

export default checkDepositTxHash