import { FC, useEffect} from 'react'
import {
  Container,
  Note
}  from './styled-components'
import PageConnector from '../page-connector'
import { TLinkCreateStep } from 'types'
import { connect } from 'react-redux'
import { RootState, IAppDispatch } from 'data/store'
import * as linkCreateAsyncActions from 'data/store/reducers/link-create/async-actions'
import { PoweredByFooter } from 'components/pages/common'
import InitialScreen from './initial-screen'
import TransactionScreen from './transaction-screen'
import { useQuery, useConnection } from 'hooks'
import { useParams } from 'react-router-dom'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'
import { useNetwork } from "wagmi"
import { useHistory } from 'react-router-dom'
import { defineTokenAddressBySymbol } from 'helpers'

const mapStateToProps = ({
  linkCreate: { step, tokenAddress, tokenType, initialized: linkCreateInitialized },
  user: { address, initialized: userInitialized, chainId, provider }
}: RootState) => ({
  step,
  address,
  userInitialized,
  tokenAddress,
  tokenType,
  chainId,
  linkCreateInitialized,
  provider
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    getInitialData: (
      tokenAmount: string
    ) => dispatch(linkCreateAsyncActions.getInitialData(
      tokenAmount
    )),
    initialize: (
      provider: any,
      urlChainId?: string,
      urlTokenAddress?: string,
      address?: string | null
    ) => dispatch(userAsyncActions.initialize(
      provider,
      urlChainId,
      urlTokenAddress,
      address
    )),
    reinitialize: (
      address: string,
      urlTokenAddress?: string,
      provider?: any,
    ) => dispatch(userAsyncActions.updateUserData(
      address,
      urlTokenAddress,
      provider
    )),
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const defineCurrentScreen = (
  step: TLinkCreateStep
) => {
  switch (step) {
    case 'initial':
      return <InitialScreen />
    case 'transaction_secure':
    case 'transaction_sign':
      return <TransactionScreen />
    default:
      return <div>...NO SCREEN TO SHOW...</div>
  }
}

const renderNote = (step: TLinkCreateStep) => {
  switch (step) {
    case 'initial':
      return <Note>
        You are currently on Linkdrop's dapp. Coinbase has partnered with Linkdrop to offer send via link functionality to you.
      </Note>
    default:
      return null
  }
}

const CreateLink: FC<ReduxType> = ({
  userInitialized,
  getInitialData,
  step,
  tokenAddress,
  address,
  chainId,
  provider,
  linkCreateInitialized
}) => {
  const query = useQuery()
  const history = useHistory()
  const {
    chain_id: urlChainId,
    token_address: urlTokenAddress
  } = useParams<{
    chain_id: string,
    token_address: string
  }>()

  const { chain } = useNetwork()
  const connectorChainId = chain ? chain.id : null
  const providerConnectionUrl = provider ? provider.connection.url : null

  useEffect(() => {
    if (!connectorChainId) { return }
    const tokenAddress = defineTokenAddressBySymbol('usdc', connectorChainId)
    history.push(`/create/${connectorChainId}/${tokenAddress}?amount=0`)
  }, [ connectorChainId ])
  

  useEffect(() => {
    if (userInitialized && tokenAddress && chainId && address && providerConnectionUrl) {
      getInitialData(
        query.get('amount') || '0'
      )
    }
  }, [userInitialized, chainId, tokenAddress, address, providerConnectionUrl])

  const screen = defineCurrentScreen(step)
  const note = renderNote(step)

  return <PageConnector urlChainId={urlChainId} urlTokenAddress={urlTokenAddress}>
    <Container>
      {screen}
      <PoweredByFooter />
      {note}
    </Container> 
  </PageConnector>
}

export default connect(mapStateToProps, mapDispatcherToProps)(CreateLink)