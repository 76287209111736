
import { Dispatch } from 'redux'
import * as actionsLink from '../actions'
import { LinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState, IAppDispatch } from 'data/store'
import * as actionCreateLink from '../../link-create/actions'
import { LinkCreateActions } from '../../link-create/types'
import * as actionUser from '../../user/actions'
import { BigNumber } from 'ethers'
import { defineNetworkId, defineTokenAddressBySymbol } from 'helpers'

export default function getTransferStatus(
  transferId: string,
  networkName: string,
  sender: string,
  tokenSymbol: string,
  callback?: (location: string) => void
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<LinkCreateActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    dispatch(actionsLink.setLoading(true))

    const {
      links: {
        links
      },
      user: {
        sdk
      }
    } = getState()

    try {
      const chainId = defineNetworkId(networkName)
      const claimLink = await sdk?.retrieveClaimLink({
        sender, transferId, chainId
      })
      if (claimLink) {
        dispatch(actionUser.setClaimLink(claimLink))
        const statusData = await claimLink.getStatus()
        if (statusData) {
          const { status } = statusData
          if (['refunded', 'redeemed', 'canceled', 'error'].includes(status)) {
            dispatch(actionsLink.setTransferStatus(status))
          } else if (status === 'deposited' || status === 'created') {
            const link = links.find(link => link.id === transferId)
            if (link) {
              callback && callback(`/links/${transferId}`)
            } else {
              dispatch(actionCreateLink.setTransferId(transferId))
              dispatch(actionCreateLink.setAmount(BigNumber.from(claimLink.amount)))
              dispatch(actionCreateLink.setStep('transaction_sign'))
              const tokenAddress = defineTokenAddressBySymbol(tokenSymbol, chainId)
              callback && callback(`/create/${chainId}/${tokenAddress}?amount=10000000`)
            }
          } else {
            dispatch(actionsLink.setTransferStatus('error'))
          }
        }
      } else {
        dispatch(actionsLink.setTransferStatus('error'))
      }
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
      dispatch(actionsLink.setTransferStatus('error'))
    }
    dispatch(actionsLink.setLoading(false))
  }
}
