import { FC, useState } from 'react'
import {
  Container,
  TitleStyled,
  CopyContainerStyled,
  ButtonStyled,
  TextStyled,
  AdditionalText
}  from './styled-components'
import Page from '../page'
import { AmountPreview } from 'components/pages/common'
import { useParams, Redirect } from 'react-router-dom'
import { Toast } from 'components/common'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { alertError, defineSystem, copyToClipboard, defineNetworkName } from 'helpers'
import { ethers } from 'ethers'
import { plausibleApi } from 'data/api'
import { TLink, TSystem } from 'types'

const mapStateToProps = ({
  links: { links },
  user: { chainId }
}: RootState) => ({
  links,
  chainId
})

type ReduxType = ReturnType<typeof mapStateToProps>

const renderButton = (
  system: TSystem,
  chainId: number | null,
  link: TLink,
  copied: boolean,
  setCopied: (copied: boolean) => void,
  resetTimeout: number
) => {
  if (system === 'android') {
    return <ButtonStyled
      appearance='action'
      onClick={async () => {
        setCopied(true)
        window.setTimeout(() => {
          setCopied(false)
        }, resetTimeout)
        copyToClipboard({ value: link.content })
        plausibleApi.invokeEvent({
          eventName: 'link_copied',
          data: {
            network: defineNetworkName(chainId)
          }
        })
        plausibleApi.invokeEvent({
          eventName: 'link_distributed',
          data: {
            network: defineNetworkName(chainId),
          }
        })
      }}
    >
      Copy link
    </ButtonStyled>
  }

  if (system === 'ios') {
    return <ButtonStyled
      appearance='action'
      onClick={async () => {
        try {
          const shareData = {
            title: "Coinbase Link",
            url: link.content,
            text: 'Hi! I am sending some crypto to you!'
          }
          await navigator.share(shareData)
          plausibleApi.invokeEvent({
            eventName: 'link_shared',
            data: {
              network: defineNetworkName(chainId)
            }
          })
          plausibleApi.invokeEvent({
            eventName: 'link_distributed',
            data: {
              network: defineNetworkName(chainId),
            }
          })
        } catch (err) {
          alertError('Your link was not shared. Please try again')
          console.error(err)
        }
      }}
    >
      Share link
    </ButtonStyled>
  }

  return null
  
}

const ShareLink: FC<ReduxType> = ({
  links,
  chainId
}) => {
  const { linkId } = useParams<{linkId: string}>()
  const [ copied, setCopied ] = useState(false)
  const link = links.find(link => link.id === linkId)
  const system = defineSystem()
  if (!link) {
    return <Redirect to='/' />
  }
  const amount = ethers.utils.formatUnits(String(link.tokenAmount), link.decimals)
  return <Page>
    <Container>
      <AmountPreview
        name={link.symbol}
        finished
        chainId={chainId}
        image={link.image}
        tokenAddress={link.tokenAddress}
        amount={amount}
      />
      <TitleStyled>
        Your claim link is ready
      </TitleStyled>
      <TextStyled>
        Anyone with this link can claim the funds. You can claim the funds yourself using this link. Funds not claimed in 30 days with be automatically returned.
      </TextStyled>
      <CopyContainerStyled
        page='link_share_screen'
        title='Claim link'
        text={link.content}
        chainId={chainId}
        hideCopyButton={system === 'android'}
        onClick={async () => {
          setCopied(true)
          window.setTimeout(() => {
            setCopied(false)
          }, 4000)
          copyToClipboard({ value: link.content })
          plausibleApi.invokeEvent({
            eventName: 'link_copied',
            data: {
              network: defineNetworkName(chainId)
            }
          })
          plausibleApi.invokeEvent({
            eventName: 'link_distributed',
            data: {
              network: defineNetworkName(chainId),
            }
          })
        }}
      />
      {renderButton(
        system,
        chainId,
        link,
        copied,
        setCopied,
        4000
      )}
      {copied && <Toast text='Copied!' />}
      <AdditionalText>Access this link again from your transaction history in Coinbase Wallet.</AdditionalText>
    </Container> 
  </Page>
}

export default connect(mapStateToProps)(ShareLink)