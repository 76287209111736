import { FC, useState, useEffect } from 'react'
import {
  ButtonStyled,
  Container,
  ButtonsContainer,
  Image,
  GradientWrapperStyled,
  PoweredByFooterStyled,
  TitleComponent,
  Subtitle,
  ImageWithMargin,
  ButtonsContainerWithPadding
} from './styled-components'
import { TStep, TProps } from './types'
import { coinbaseDeeplink, coinbaseOriginalDeeplink, defineSystem } from 'helpers'
import { TSystem } from 'types'
import Icons from 'icons'
import { AdditionalNote, OverlayScreen } from 'components/common'
import LinkdropLogo from 'images/linkdrop-header.png'
import { PopupContents } from '../index'
import { defineNetworkName } from 'helpers'
import { plausibleApi } from 'data/api'
import { useLocation } from 'react-router-dom'

const defineStoreLink = (system: TSystem) => {
  if (system === 'android') {
    return 'https://play.google.com/store/apps/details?id=org.toshi&referrer=utm_source%3DWallet_LP'
  }
  return 'https://apps.apple.com/app/apple-store/id1278383455?pt=118788940&ct=Wallet_LP&mt=8'
}

const InitialScreen: FC<{ setStep: (step: TStep) => void }> = ({
  setStep
}) => {
  const location = useLocation()
  return <>
    <GradientWrapperStyled enableGradient>
      <Image>
        <Icons.ReceivedIcon />
      </Image>
    </GradientWrapperStyled>
    <TitleComponent>{renderTitle(location.pathname)}</TitleComponent>
    <Subtitle>{renderDescription(location.pathname)}</Subtitle>
    <ButtonsContainer>
      <PoweredByFooterStyled />
      <ButtonStyled
        appearance='action'
        onClick={() => {
          const link = document.createElement('a')
          link.href = coinbaseDeeplink(window.location.href)
          link.target = '_blank'
          link.click()
          setStep('instruction')
        }}
      >
        Proceed
      </ButtonStyled>
    </ButtonsContainer>
  </>
}

const Instruction: FC<{
  setShowPopup: (showPopup: boolean) => void
}> = ({
  setShowPopup
}) => {
  const system = defineSystem()
  const location = useLocation()

  return <>
    <ImageWithMargin>
      <Icons.ReceivedUSDCIcon />
    </ImageWithMargin>
    <TitleComponent>{renderTitle(location.pathname)}</TitleComponent>
    <Subtitle>{renderDescription(location.pathname)}</Subtitle>
    <ButtonsContainerWithPadding>
      <PoweredByFooterStyled />
      <ButtonStyled
        target='_blank'
        appearance='action'
        onClick={() => {
          const link = document.createElement('a')
          link.href = coinbaseOriginalDeeplink(window.location.href)
          link.target = '_blank'
          link.click()
        }}
      >
        Open Coinbase Wallet
      </ButtonStyled>
      <ButtonStyled href={defineStoreLink(system)}>
        Download Coinbase Wallet
      </ButtonStyled>
    </ButtonsContainerWithPadding>
    <AdditionalNote
      text='Need help? Read here how to proceed'
      position='bottom'
      onClick={() => {
        setShowPopup(true)
      }}
    />
  </>
}

const renderContent = (
  step: TStep,
  setStep: (step: TStep) => void,
  setShowPopup: (showPopup: boolean) => void
) => {
  switch (step) {
    case 'initial':
      return <InitialScreen setStep={setStep} />
    case 'instruction':
      return <Instruction setShowPopup={setShowPopup} />
  }
}

const renderTitle = (path: string) => {
  if (path === '/usdc') {
    return 'You have received USDC'
  }

  return 'Send USDC with a link'
}

const renderDescription = (path: string) => {
  if (path === '/usdc') {
    return 'Open Coinbase Wallet or download it to claim your USDC!'
  }

  return 'You can now send USDC to a link that anyone can use to claim. Neither you nor the recipient will need to pay network fees!'
}

const CoinbaseConnectMobile: FC<TProps> = ({
  chainId
}) => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/usdc') {
      plausibleApi.invokeEvent({
        eventName: 'link_opened_web',
        data: {
          network: defineNetworkName(Number(chainId))
        }
      })
    }
  }, [])

  const [ step, setStep ] = useState<TStep>('initial')
  const [ showPopup, setShowPopup ] = useState<boolean>(false)
  return <Container>
    {renderContent(
      step,
      setStep,
      setShowPopup
    )}
    {showPopup && <OverlayScreen
      headerLogo={LinkdropLogo}
      title='Need Help?'
      onCloseAction={() => { setShowPopup(false) }}
      mainAction={() => { setShowPopup(false) }}
    >
      <PopupContents />
    </OverlayScreen>}
  </Container>
}

export default CoinbaseConnectMobile