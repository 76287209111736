import { polygon, polygonMumbai, base } from 'wagmi/chains'
import { createConfig, configureChains } from 'wagmi'
import { coinbaseConnector } from './coinbase-connector'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'

const { REACT_APP_INFURA_ID } = process.env

const { publicClient, webSocketPublicClient } = configureChains(
  [polygon, polygonMumbai, base],
  [
    infuraProvider({ apiKey: REACT_APP_INFURA_ID as string }),
    publicProvider()
  ],
)

 
export const config = createConfig({
  publicClient,
  webSocketPublicClient,
  connectors: [coinbaseConnector]
})
