import { action } from 'typesafe-actions'
import { Constants } from './constants'
import { ethers } from 'ethers'

export function setName(name: string) {
  return action(Constants.TOKEN_SET_NAME, {
    name
  })
}

export function setDescription(description: string) {
  return action(Constants.TOKEN_SET_DESCRIPTION, {
    description
  })
}

export function setBalance(balance: ethers.BigNumber) {
  return action(Constants.TOKEN_SET_BALANCE, {
    balance
  })
}

export function setImage(image: string) {
  return action(Constants.TOKEN_SET_IMAGE, {
    image
  })
}

export function setDecimals(decimals: number) {
  return action(Constants.TOKEN_SET_DECIMALS, {
    decimals
  })
}

export function setTokenId (tokenId: string) {
  return action(Constants.TOKEN_SET_TOKEN_ID, { tokenId })
}

export function setTokenAddress(tokenAddress: string) {
  return action(Constants.TOKEN_SET_TOKEN_ADDRESS, { tokenAddress })
}

export function setAmount (amount: string) {
  return action(Constants.TOKEN_SET_AMOUNT, { amount })
}
