import { FC, useState, useEffect, useRef } from 'react'
import { InputStyled, Container, Image, TitleStyled, TextStyled } from './styled-components'
import { ButtonStyled } from '../styled-components'
import * as linkCreateAsyncActions from 'data/store/reducers/link-create/async-actions'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { ethers } from 'ethers'
import { convertFiatToTokens, defineNetworkName, defineRealNetworkName } from 'helpers'
import { TextLink } from 'components/common'
import Icons from 'icons'
import { plausibleApi } from 'data/api'
const { REACT_APP_LINK_FUNDS_LIMIT } = process.env

const mapStateToProps = ({
  linkCreate: { step, initialized, tokenAddress, tokenType, loading, tokenAmount },
  user: { address, chainId },
  token: { balance, decimals, name }
}: RootState) => ({
  step,
  address,
  initialized,
  tokenAddress,
  chainId,
  tokenType,
  loading,
  balance,
  decimals,
  name,
  tokenAmount
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    setTokenAmount: (amount: string) => dispatch(linkCreateAsyncActions.setTokenAmount(amount))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const InitialScreen: FC<ReduxType> = ({
  setTokenAmount,
  loading,
  balance,
  decimals,
  name,
  address,
  chainId,
  tokenAddress,
  tokenAmount
}) => {

  const userLimit = ethers.utils.formatUnits(String(balance), decimals)
  const [ value, setValue ] = useState<string>(`0`)
  const tokensToShare = `${convertFiatToTokens((value || '0'), tokenAddress as string)}` // USD => USDT
  const userLimitFailed = ethers.utils.parseUnits(tokensToShare, decimals).gt(ethers.utils.parseUnits(userLimit, decimals))
  const linkFundsLimit = REACT_APP_LINK_FUNDS_LIMIT || '100'
  const applicationLimitFailed = ethers.utils.parseUnits(tokensToShare, decimals).gt(ethers.utils.parseUnits(linkFundsLimit, decimals))
  const networkName = defineRealNetworkName(chainId)
  const notConnected = !address || !chainId
  const ref = useRef(null)

  useEffect(() => {
    plausibleApi.invokeEvent({
      eventName: 'sender_flow_started',
      data: {
        network: defineNetworkName(chainId)
      }
    })
  }, [])

  const defineInputNote = () => {
    if (loading) {
      return `Loading...`
    }
    if (notConnected) {
      return `Error occured: Coinbase wallet is not connected`
    }
    if (applicationLimitFailed) {
      return `You can only send up to $${linkFundsLimit}`
    }
    if (userLimitFailed) {
      return `You do not have enough ${name} on ${networkName}. Only $${userLimit} available.`
    }

    return `${parseFloat(tokensToShare)} ${name}`
  }

  useEffect(() => {
    if (!tokenAmount) { return }
    const amountToCreateLink = parseFloat(ethers.utils.formatUnits(tokenAmount, decimals))
    setValue(`${amountToCreateLink}`)
  }, [tokenAmount])

  return <Container>
    <Image>
      <Icons.DecorationImageIcon />
    </Image>
    <TitleStyled>
      Send USDC to a link that anyone can use to claim
    </TitleStyled>
    <TextStyled>
      Funds will be withdrawn and can be claimed by a user with the link we’ll provide. Neither you nor the recipient will need to pay network fees! Funds not claimed in 30 days will be automatically returned. <TextLink href='https://pay.linkdrop.io/' target='_blank'>Learn more</TextLink>
    </TextStyled>
    <InputStyled
      prefix='$'
      refProp={ref}
      title='Enter amount'
      value={value}
      type="text"
      pattern="\d*(.,d+)?"
      inputMode="decimal"
      onFocus={() => {
        if (value === '0') {
          setValue('')
        }
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          const refInput = ref.current !== null ? ref.current as HTMLInputElement : null
          if (refInput) {
            refInput.blur()
          }
        }
      }}
      onBlur={() => {
        if (value === '') {
          setValue('0')
        }
      }}
      disabled={loading || notConnected}
      onChange={(value) => {
        let valueDelimeters = value.match(/\.|\,/g);
        let hasTwoDelimeters = valueDelimeters !== null && valueDelimeters.length == 2
        console.log(hasTwoDelimeters)
        if (value.length > 7) {
          return value
        }
        if (hasTwoDelimeters) {
          return value
        } else if (value === '.' || value === ',') {
          setValue(`0`)
        } else if (/^[0-9.,]+$/.test(value) || value === '') {
          setValue(value.replace(',', '.'))
        } else if (!value) {
          setValue(`0`)
        } else if (value === '') {
          setValue(`0`)
        }
        return value
      }}
      error={userLimitFailed || applicationLimitFailed ? defineInputNote() : undefined}
      note={!userLimitFailed && !applicationLimitFailed ? defineInputNote() : undefined}
    />
    <ButtonStyled
      appearance='action'
      loading={loading}
      disabled={
        loading ||
        userLimitFailed ||
        Number(value) === 0 ||
        applicationLimitFailed
      }
      onClick={() => {
        setTokenAmount(tokensToShare)
      }}
    >
      {loading ? 'Loading' : <>Continue<Icons.NextIcon /></>}
    </ButtonStyled>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(InitialScreen)