import { Dispatch } from 'redux';
import * as actions from '../actions';
import { UserActions } from '../types';
import { LinkActions } from '../../link/types'
import * as actionsDrop from '../../link/actions';
import { defineJSONRpcUrl, defineNetworkId, defineNetworkName, defineTokenAddressBySymbol } from 'helpers'
import { ethers } from 'ethers'
import { LinkdropPay } from 'linkdrop-p2p-sdk'
import { LinkCreateActions } from '../../link-create/types'
import * as linkCreateActions from '../../link-create/actions'
import { providers } from 'ethers'

const { REACT_APP_INFURA_ID, REACT_APP_ZUPLO_API_KEY } = process.env

const updateUserData = (
  address: string,
  urlTokenAddress?: string,
  connector?: any
) => {
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<LinkActions> & Dispatch<LinkCreateActions>
  ) => {

    dispatch(actions.setLoading(true))
    try {
      dispatch(actions.setHasConnector(true))
      dispatch(actions.setAddress(address))
      const provider = new providers.Web3Provider(await connector.getProvider())
      const network = await provider.getNetwork()

      if (provider) {
        if (urlTokenAddress) {
          dispatch(linkCreateActions.setTokenAddress(urlTokenAddress))
        }
        const signer = await provider.getSigner()
        // if (expectedChainId && Number(chainId) !== expectedChainId) {
        //   if (
        //     window &&
        //     window.ethereum
        //   ) {
        //     await switchNetwork(provider, expectedChainId) // 80001 is hardcode for now
        //   }
        // } else {
          dispatch(actions.setChainId(network.chainId))
          dispatch(actions.setSigner(signer))
          dispatch(actions.setUserProvider(provider))
          const jsonRpcUrl = defineJSONRpcUrl({
            chainId: Number(network.chainId),
            infuraPk: REACT_APP_INFURA_ID as string
          })
          const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)
          dispatch(actions.setProvider(jsonRpcProvider))
          const sdk = new LinkdropPay({
            apiKey: REACT_APP_ZUPLO_API_KEY as string,
            baseUrl: window.location.origin,
          })
          dispatch(actions.setSDK(sdk))
          dispatch(actionsDrop.setStep('short_code_loading'))
        // }
      }
      
    } catch (err) {
      alert('Error occured with connector update')
    }
    dispatch(actions.setLoading(false))
  }
}

export default updateUserData