import * as actions from '../actions'
import { Dispatch } from 'redux'
import { TokenActions } from '../types'

export type TGetTokenBalance = (
  dispatch: Dispatch<TokenActions>,
  address: string,
  contract: any
) => Promise<boolean>


const getTokenBalance: TGetTokenBalance = async (
  dispatch,
  address,
  contractInstance
) => {
  try {
    const tokenAmount = await contractInstance.balanceOf(address)
    dispatch(actions.setBalance(tokenAmount))
    return true
  } catch (err) {
    console.log({
      err
    })
    return false
  }
}

export default getTokenBalance