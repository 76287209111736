import { FC, ReactElement, useEffect, useState } from 'react'
import InitialScreen from './initial-screen'
import ClaimingFinished from './claiming-finished'
import ClaimingProcess from './claiming-process'
import ErrorTransactionPage from './error-transaction'
import ErrorNoConnectionPage from './error-no-connection'
import ErrorServerFail from './error-server-fail'
import ErrorLinkNotFound from './error-link-not-found'
import ErrorLinkNoConnection from './error-link-no-connection'
import ErrorLink from './error-link'
import ShortCodeLoading from './short-code-loading'
import {
  AlreadyClaimed,
  ErrorLinkExpired,
  ErrorScreen,
  LogoLoader
} from 'components/pages/common'
import PageConnector from '../page-connector'
import { TLinkStep } from 'types'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { Container } from './styled-components'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { LinkActions } from 'data/store/reducers/link/types'
import { Dispatch } from 'redux'
import { defineSystem, coinbaseDeeplink } from 'helpers'
import { useParams } from 'react-router-dom'
import { useQuery } from 'hooks'

const mapStateToProps = ({
  user: { address, provider, chainId, initialized: userInitialized },
  link: { step, claimCode, wallet, initialized: linkInitialized },
}: RootState) => ({
  address,
  step,
  provider,
  chainId,
  userInitialized,
  linkInitialized,
  claimCode,
  wallet
})

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions> & IAppDispatch) => {
  return {
    getLinkData: (
      link: string,
      token_symbol: string
    ) => dispatch(
      linkAsyncActions.getLinkFromURL(
        link,
        token_symbol
      )
    ),
    getInitialData: () => dispatch(linkAsyncActions.getInitialData())
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

type TDefineStep = (step: TLinkStep) => ReactElement

const defineCurrentScreen: TDefineStep = step => {
  switch (step) {
    case 'initial':
      return <InitialScreen />
    case 'claiming_process':
      return <ClaimingProcess />
    case 'claiming_finished':
      return <ClaimingFinished />
    case 'already_claimed':
      return <AlreadyClaimed />
    case 'error':
      return <ErrorScreen />
    case 'error_transaction':
      return <ErrorTransactionPage />
    case 'error_no_connection':
      return <ErrorNoConnectionPage />
    case 'error_server_fail':
      return <ErrorServerFail />
    case 'link_expired':
      return <ErrorLinkExpired />
    case 'error_link_not_found':
      return <ErrorLinkNotFound />
    case 'error_link_no_connection':
      return <ErrorLinkNoConnection />
    case 'error_link':
      return <ErrorLink />
    case 'short_code_loading':
      return <ShortCodeLoading />
    default:
      return <LogoLoader />
  }
}

const ClaimPage: FC<ReduxType> = ({
  step,
  getLinkData,
  linkInitialized,
  userInitialized,
  getInitialData
}) => {
  const queryParams = useQuery()
  const queryParamsChainId = queryParams && queryParams.get('c')

  const [ initialized, setInitialized ] = useState(false)

  const { token_symbol } = useParams<{
    token_symbol: string
  }>()
  
  useEffect(() => {
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized && userInitialized) {
      getLinkData(
        window.location.href,
        token_symbol
      )
    }
  }, [userInitialized, initialized])

  useEffect(() => {
    if (initialized && linkInitialized) {
      getInitialData()
    }
  }, [linkInitialized, initialized])

  const screen = defineCurrentScreen(step)
  
  return <PageConnector urlChainId={queryParamsChainId}>
    <Container>
      {screen}
    </Container> 
  </PageConnector>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ClaimPage)
