import { FC } from 'react'
import {
  AmountFiat,
  AmountTokens,
  TokenIcon,
  NetworkIcon,
  NetworkName
} from './styled-components'
import TProps from './types'
import { convertTokensToFiat, defineNetworkName } from 'helpers'
import PolygonLogoImage from 'images/polygon-logo.png'
import BaseLogoImage from 'images/base-logo.png'
import GradientWrapper from '../gradient-wrapper'

const defineNetworkLogo = (chainId: number | null) => {
  if (chainId === 8453) {
    return BaseLogoImage
  }

  return PolygonLogoImage
}

const AmountPreview: FC<TProps> = ({
  amount,
  image,
  tokenAddress,
  finished,
  name,
  chainId
}) => {
  const fiatAmount = convertTokensToFiat(amount, tokenAddress)
  const networkName = defineNetworkName(chainId)
  return <GradientWrapper enableGradient={finished}>
    <AmountFiat>
      ${parseFloat(fiatAmount)}
    </AmountFiat>
    <AmountTokens>
      of USDC ({parseFloat(amount)} {name}) on <NetworkName>{networkName}</NetworkName>
    </AmountTokens>
    {image && <TokenIcon src={image} alt='Token Image' />}
    <NetworkIcon src={defineNetworkLogo(chainId)} />
  </GradientWrapper>
  
} 

export default AmountPreview